import baseTheme from '@fify.at/gatsby-theme-base/src/gatsby-plugin-theme-ui/';
import merge from 'lodash/merge';

export default merge(
  // Use the theme from gatsby-theme-base as a default.
  baseTheme,
  {
    fonts: {
      heading: `'Muli', serif`,
      body: `"Muli", sans-serif`,
    },

    fontWeights: {
      body: 500,
      heading: 700,
      bold: 800,
      strong: 900,
    },

    colors: {
      text: '#1d1d1b',
      background: '#fff',
      primary: '#6a82a8',
      primaryHover: '#000',
      onPrimary: '#fff',
      secondary: '#000',
      onSecondary: '#fff',
      accent: '#709831',
      muted: '#f2f4f9',
    },

    space: [0, 4, 8, 16, 24, 32, 40, 48, 56],

    breakpoints: ['576px', '768px', '992px', '1200px', '1600px'],

    sizes: {
      container: [null, 540, 720, 960, 1040, 1368],
      gutter: 32,
    },

    radii: { default: 3 },
    shadows: {},

    fontSizes: [14, 16, 18, 28, 32, 40],

    lineHeights: ['16px', '32px', '32px', '48px', '48px', '64px'],

    text: {
      default: {
        fontSize: 1,
        lineHeight: 1,
        fontFamily: 'body',
        fontWeight: 'body',
      },

      marker: {
        fontSize: 3,
        lineHeight: 3,
      },

      headline: {
        fontSize: [3, null, null, 3, 4],
        lineHeight: 'normal',
        fontWeight: '900',
        strong: { fontFamily: 'heading', fontWeight: 'heading' },
        textAlign: 'center',
      },

      subheadline: {
        fontSize: [3, null, null, 3, 4],
        lineHeight: 'normal',
        fontWeight: 'heading',
        strong: { fontFamily: 'heading', fontWeight: 'heading' },
        textAlign: 'center',
      },
    },

    buttons: {
      primary: {
        color: 'onPrimary',
        backgroundColor: 'accent',
        border: 'none',
        ':hover': {
          color: 'onSecondary',
          backgroundColor: 'secondary',
          textDecoration: 'none',
        },
      },

      onDark: {
        color: 'onPrimary',
        backgroundColor: 'accent',
        border: 'none',
        ':hover': {
          color: 'onSecondary',
          backgroundColor: 'secondary',
          textDecoration: 'none',
        },
      },

      dark: {
        color: 'onPrimary',
        backgroundColor: 'primary',
        border: '1px solid',
        borderColor: 'primary',
        ':hover': {
          color: 'primary',
          backgroundColor: 'transparent',
          textDecoration: 'none',
        },
      },
    },

    styles: {
      global: {
        '::selection': {
          backgroundColor: 'primary' /* WebKit/Blink Browsers */,
          color: '#fff' /* WebKit/Blink Browsers */,
        },
        '::-moz-selection': {
          backgroundColor: 'primary' /* Gecko Browsers */,
          color: '#fff' /* WebKit/Blink Browsers */,
        },

        '.ReactModal__Overlay': {
          zIndex: 3,
          '&&&': { backgroundColor: 'rgba(229,239,255,0.9) !important' },
          transition: 'opacity 400ms ease-in-out',
          opacity: 0,
        },

        '.ReactModal__Overlay--after-open': {
          opacity: 1,
        },

        '.ReactModal__Overlay--before-close': {
          opacity: 0,
        },

        '.ReactModal__Content': {
          borderRadius: '0 !important',
          top: ['0px !important', null, null, '40px !important'],
          bottom: ['0px !important', null, null, '40px !important'],
          left: ['0px !important', null, null, '40px !important'],
          right: ['0px !important', null, null, '40px !important'],
        },

        '.btn-primary:hover': {
          backgroundColor: 'text',
          borderColor: 'text',
        },
        '.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle': {
          backgroundColor: 'secondary',
          borderColor: 'secondary',
          color: 'onPrimary',
        },
        p: {
          marginBottom: '2rem',
        },
        a: {
          color: 'accent',
        },
        ul: {
          marginBottom: '2rem',
        },
        ol: {
          marginBottom: '2rem',
        },
        li: {
          fontSize: 2,
        },
        body: {
          margin: 0,
          padding: 0,
          fontSize: '1rem',
          lineHeight: 1.5,
          fontFamily: 'body',
          fontWeight: 'body',
        },
        h1: {
          fontSize: [4, null, null, 4, 5],
          lineHeight: 'normal',
          fontWeight: 'heading',
          strong: { fontWeight: 'strong' },
        },
        h2: {
          fontSize: [3, null, null, 3, 4],
          lineHeight: 'normal',
          fontWeight: 'heading',
          strong: { fontWeight: 'strong' },
        },
        h3: {
          fontSize: [1, null, null, 2, 3],
          lineHeight: 'normal',
          fontWeight: 'heading',
          strong: { fontWeight: 'strong' },
          marginBottom: 5,
        },
      },
    },
    layout: {
      HeroSectionContainer: {
        FirstBox: {
          backgroundImage: [
            'linear-gradient(270deg, rgba(0,0,0,0.1), rgba(0,0,0,0.9))',
            null,
            'linear-gradient(90deg, rgba(0,0,0,0.1), rgba(0,0,0,0.9))',
          ],
          paddingRight: [3, 4],
          paddingLeft: 6,
          h1: {
            fontSize: [3, null, null, 4],
            lineHeight: 'normal',
            fontWeight: 'heading',
          },
        },
        SecondBox: {
          paddingLeft: 6,
          paddingRight: [3, 4],
          paddingBottom: 1,
          backgroundImage: [
            'linear-gradient(270deg, rgba(0,0,0,0.1), rgba(0,0,0,0.9))',
            null,
            'linear-gradient(90deg, rgba(0,0,0,0.1), rgba(0,0,0,0.9))',
          ],
          h1: {
            fontSize: [1, null, null, 2, 3],
            lineHeight: 'normal',
            strong: {
              fontWeight: 'normal',
            },
          },
        },
        ThirdBox: {
          paddingBottom: 1,
        },
      },
    },
  }
);
